import React, { Component } from 'react'
import styled from 'styled-components'
import {
  Spinner,
} from "@shopify/polaris"
import formatCurrency from '../../../helpers/formatCurrency';
import Flickity from 'react-flickity-component'
import AiProductIcon from '../../../images/AiProductIcon.png';
import AutopilotProductIcon from '../../../images/AutopilotProductIcon.png';
import "flickity/css/flickity.css";
import productPricePreview from './utils/productPricePreview';


class UpsellPreview extends Component {
  constructor(props) {
    super(props);
    this.state = { title: props.variants ? props.variants[0].title : null, loading: false, selection: null };
    this.handleChange = this.handleChange.bind(this);
    this.handleDropDownChange = this.handleDropDownChange.bind(this);
    this.addToCart = this.addToCart.bind(this);
  }
  
  //upsellStyle['upsellplus-container']['minWidth'] = 350; // For preview side only (not for checkout.liquid)
  /* copied / adapted from checkout.liquid markup / css */

  handleChange(event) {
    this.setState({title: event.target.value});
  }
  handleDropDownChange(variants, event) {
    this.setState({selection: {title: event.target.value, variants: variants}})
  }
  addToCart(){
    this.setState({loading: true});
    setTimeout(function(){
      this.setState({loading: false});
    }.bind(this),1000);  
  }
  
  
  render() {
    const { product, checkoutOfferType, layout, generalLayout, productNumber, variants, banner, message, title, subtitle, cta, ctaBackgroundColor,ctaTextColor, ctaButtonTextColor, ctaButtonBorderColor, offerBackgroundColor, widgetBorderColor, buttonBorderRadius, widgetBorderRadius, buttonBorder, widgetBorder, css, image, price, discount = 0, compareAtPrice, type, shopData, shopBrain, productEdit } = this.props
    const locale = shopData && shopData.shopData && shopData.shopData.primary_locale || "en-US";
    const currency = shopData && shopData.shopData && shopData.shopData.currency || "USD";
    const insertBeforeLastOccurrence = (strToSearch, strToFind, strToInsert) => {
      if(strToSearch){
        var n = strToSearch.lastIndexOf(strToFind);
        if(n < 0){
           return strToSearch;
        }
        return strToSearch.substring(0,n) + strToInsert + strToSearch.substring(n);
      }else{
        return strToSearch;
      }
    }
    let selectedVariant
    if(variants){
      //TODO: change it to id
      selectedVariant = variants.find(variant => variant.title === this.state.title)
    }
    let carouselSelectedVariant
    if(this.state.selection){
      carouselSelectedVariant = this.state.selection.variants.find(variant => variant.title === this.state.selection.title)
    }
    const style = this.props.css !="" ? this.props.css 
    + `
    .upsellplus-container {
      flex-wrap: wrap;
    }
    .upsellplus-title-container {
      max-width: none;
    }
    .upsellplus-button {
      min-width: 100px;
    }
    .upsellplus-button-mobile {
      width: 100%;
      float: none;
      height: 45px;
      flex-basis: 100%;
    }
    .upsellplus-compare-at-price {
      display: inline;
    }
    .upsellplus-original-price-mobile {
      margin-right: 0;
    }
    .upsellplus-compare-at-price-mobile {
      display: block;
    }
    .upsellplus-image {
      border-radius: 5px;
    }
    .flickity-button {
      background: transparent;
    }
    .flickity-prev-next-button.previous {
      left: -12px;
    }
    .flickity-prev-next-button.next {
      right: -35px;
    }
    .flickity-page-dots {
      bottom: -10px;
    }
    .flickity-slider .upsellplus-container {
      left: 3% !important;
    }
    ` 
    :`
    .upsellplus-app {
      padding-bottom: 15px;
    }
    .upsellplus-container {
      border-style: solid;
      color: #212b36;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 10px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: stretch;
      align-content: center;
      flex-wrap: wrap;
    }
    .upsellplus-banner {
      box-sizing: border-box;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 20px;
      line-height: 18px;
      text-align: center;
    }
    .upsellplus-image-container {
      box-sizing: border-box;
      display: inline-block;
      width: 64px;
      height: 64px;
      border-radius: 5px;
      border: 1px solid #aaa;
      margin-right: 6px;
      background: white;
      align-self: center;
    }
    .upsellplus-image {
      object-fit: cover;
      object-position: center;
      height: 62px;
      width: 62px;
      border-radius: 5px;
    }
    .upsellplus-title-container {
      box-sizing: border-box;
      display: flex;
      flex: 2;
      flex-direction: column;
      margin: auto;
      min-width: 100px;
      padding: 5px;
      text-align: left;
      padding-right: 12px;
    }
    .upsellplus-title {
      box-sizing: border-box;
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 2px;
      line-height: 18px;
      text-align: left;
    }
    .upsellplus-price-container {
      text-align: right;
    }
    .upsellplus-price-container-left {
      text-align: left;
    }
    .upsellplus-price {
      box-sizing: border-box;
      color: #212b36;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
      text-align: right;
    }
    .upsellplus-price-left {
      text-align: left;
    }
    .upsellplus-original-price {
      text-decoration: line-through;
      margin-right: 5px;
      display:inline;
    }
    .upsellplus-compare-at-price {
      box-sizing: border-box;
      color: #212b36;
      font-size: 17px;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
      text-align: right;
      display:inline;
    }
    .upsellplus-subtitle {
      box-sizing: border-box;
      font-size: 14px;
      line-height: 20px;
      text-align: left;
    }
    .upsellplus-message {
      font-size: 10px;
    }
    .upsellplus-action-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 5px 0;
    }
    .upsellplus-selection {
      margin-top: 5px;
      padding: 0 3px;
      max-width: 130px;
      border: 1px solid #767676;
      border-radius: 3px;
    }
    .upsellplus-button {
      align-items: center;
      box-shadow: rgba(22,29,37,.05) 0 1px 0 0;
      box-sizing: border-box;
      cursor: pointer;
      font-size: 13.3333px;
      justify-content: center;
      line-height: 1;
      margin: 10px 0 0;
      padding: 5px 20px;
      position: relative;
      text-align: center;
      text-decoration: none;
      transition: background, border, box-shadow;
      user-select: none; 
      border-style: solid;
      float: right;
      min-width: 100px;     
    }
    .upsellplus-button-content {
      font-weight: var(--p-button-font-weight, 400);
      line-height: 16px;
      text-transform: initial;
      letter-spacing: initial;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 1px;
      min-height: 1px;  
    }
    .upsellplus-button-text {
      font-size: 14px;
      font-weight: 500;
    }
    .upsellplus-button-mobile {
      width: 100%;
      float: none;
      height: 45px;
      flex-basis: 100%;
    }
    .upsellplus-original-price-mobile {
      margin-right: 0;
    }
    .upsellplus-compare-at-price-mobile {
      display: block;
    }
    .flickity-button {
      background: transparent;
    }
    .flickity-prev-next-button.previous {
      left: -12px;
    }
    .flickity-prev-next-button.next {
      right: -35px;
    }
    .flickity-page-dots {
      bottom: -10px;
    }
    .flickity-slider .upsellplus-container {
      left: 3% !important;
    }
  `
    
    const UpsellPlusWrapper = styled.div`${style}`
    let showProducts = "manual" !== checkoutOfferType ? new Array(productNumber).fill({title:title, subtitle:subtitle, compareAtPrice:compareAtPrice, variants: [{price: 200}]}) : product
    const productIcon = checkoutOfferType === 'ai' ? AiProductIcon : AutopilotProductIcon;

    if(Array.isArray(showProducts) && showProducts.length > 0 && "one"===layout){
      showProducts = [showProducts[0]]
    }
    let flickityOptions = {
      wrapAround: true
    }
    if(productEdit > -1 && Array.isArray(showProducts) && showProducts.length > 0){
      flickityOptions = {
        initialIndex: productEdit,
        wrapAround: true
      }
    }else{
      if(this.state.selection){
        const variantIndex = product.findIndex(p => p.variants[0].id === this.state.selection.variants[0].id)
        flickityOptions = {
          initialIndex: variantIndex,
          wrapAround: true
        }
      }
    }
    return (
    <UpsellPlusWrapper>
    <div className="upsellplus-app">
    <p className="upsellplus-banner" style={{color:ctaTextColor}}>{banner || (Array.isArray(showProducts) && showProducts.length > 0 && showProducts[0].product? "":"Callout banner!")}</p>
    { Array.isArray(showProducts) && showProducts.length > 1 && ("carousel"===layout || ("responsive"===layout && "Mobile" === type))?
    <div style={{width:'395px'}}>
    <Flickity options={flickityOptions}>
       {showProducts.map((p,index) => {
      return <div className="upsellplus-container" style={{
        width:'94%',
        minHeight: '125px',
        marginRight: '10px',
        padding: "Desktop" === type ? '0':'10px 10px',
        backgroundColor:offerBackgroundColor,
        borderRadius: widgetBorderRadius,
        borderWidth: widgetBorder,
        borderColor: widgetBorderColor
        }} key={index}>
          <div className="upsellplus-image-container" style={{marginLeft: "Desktop" === type ? '15px':'0'}}>
              <img className="upsellplus-image" 
              src={("manual" !== checkoutOfferType ?
                productIcon
                : 
                ((!this.state.selection || (carouselSelectedVariant && this.state.selection.variants && p.variants && this.state.selection.variants[0].id !== p.variants[0].id)) ?
                p.variants && Array.isArray(p.variants) && p.variants.length > 0 &&
                (p.variants[0]?.image ?
                 insertBeforeLastOccurrence(p.variants[0].image, ".", "_100x100") 
                 :
                 insertBeforeLastOccurrence(p.product.image, ".", "_100x100") )
                :  
                carouselSelectedVariant && carouselSelectedVariant.image && insertBeforeLastOccurrence(carouselSelectedVariant.image, ".", "_100x100"))
                )
                || 'https://via.placeholder.com/200'} 
              alt=""
              style={{padding: checkoutOfferType !== 'manual' ? '7px' : 'unset'}}
              />
          </div>
          <div className="upsellplus-title-container">
              <p className="upsellplus-title" style={{
                color:ctaTextColor
                }}>
                  {p.title || (p.product && p.product.title) || 'Product title'}
                </p>
                {
                "center" === generalLayout ?
                 (
                  p.variants && Array.isArray(p.variants) && p.variants.length > 0 ?
                  (!carouselSelectedVariant || (carouselSelectedVariant && this.state.selection && this.state.selection.variants && this.state.selection.variants[0].id !== p.variants[0].id)) ?
                    (p.compareAtPrice && p.compareAtPrice.sym && "" !== p.compareAtPrice.value) || (p.compareAtPrice && !p.compareAtPrice.sym && "" !== p.compareAtPrice.value)?
                    <div className="upsellplus-price-container upsellplus-price-container-left">
                      <p className={"Desktop" === type ? "upsellplus-price upsellplus-original-price" : "upsellplus-price upsellplus-original-price upsellplus-original-price-mobile"}>{formatCurrency(p.variants[0].price, locale, currency)}</p>
                      <p className={"Desktop" === type ? "upsellplus-compare-at-price" : "upsellplus-compare-at-price upsellplus-compare-at-price-mobile"}>
                        { productPricePreview(p.compareAtPrice, p.variants[0].price, locale, currency) }
                      </p>
                    </div>
                      :
                      <p className="upsellplus-price upsellplus-price-left">{p.variants && Array.isArray(p.variants) && p.variants.length > 0 ? formatCurrency(p.variants[0].price, locale, currency):""}</p>
                    :
                      (p.compareAtPrice && p.compareAtPrice.sym && "" !== p.compareAtPrice.value) || (p.compareAtPrice && !p.compareAtPrice.sym && "" !== p.compareAtPrice.value)?
                      <div className="upsellplus-price-container upsellplus-price-container-left">
                        <p className={"Desktop" === type ? "upsellplus-price upsellplus-original-price" : "upsellplus-price upsellplus-original-price upsellplus-original-price-mobile"}>{formatCurrency(carouselSelectedVariant.price, locale, currency)}</p>
                        <p className={"Desktop" === type ? "upsellplus-compare-at-price" : "upsellplus-compare-at-price upsellplus-compare-at-price-mobile"}>
                          { productPricePreview(p.compareAtPrice, carouselSelectedVariant.price, locale, currency) }
                        </p>
                      </div>
                      :
                      <p className="upsellplus-price upsellplus-price-left">{formatCurrency(carouselSelectedVariant.price, locale, currency)}</p>

                  :
                    false
                  
                )
                :
                false
              }
              <p className="upsellplus-subtitle" style={{color:ctaTextColor}}>{p.subtitle || (p.product ? '':'Product Subtitle')}</p>
              <p className="upsellplus-message" style={{color:ctaTextColor}}>{p.message || (p.product ? '':'Marketing message')}</p>
              {p.variants && Array.isArray(p.variants) && p.variants.length > 1 ?
                <select value={this.state.selection ? carouselSelectedVariant.title : p.variants[0].title} onChange={(event) => this.handleDropDownChange(p.variants, event)} className="upsellplus-selection">
                  {p.variants.map((variant, index) => 
                    <option value={variant.title} key={index}>{variant.title}</option>
                  )} 
                </select>
                :
                false
              }
          </div>
          <div className="upsellplus-action-container" style={{padding: '5px 15px 5px 0'}}>
              { "center" !== generalLayout ?
                 (
                  p.variants && Array.isArray(p.variants) && p.variants.length > 0 ?
                  (!carouselSelectedVariant || (carouselSelectedVariant && this.state.selection && this.state.selection.variants && this.state.selection.variants[0].id !== p.variants[0].id)) ?
                    (p.compareAtPrice && p.compareAtPrice.sym && "" !== p.compareAtPrice.value) || (p.compareAtPrice && !p.compareAtPrice.sym && "" !== p.compareAtPrice.value)?
                    <div className="upsellplus-price-container">
                      <p className={"Desktop" === type ? "upsellplus-price upsellplus-original-price" : "upsellplus-price upsellplus-original-price upsellplus-original-price-mobile"}>{formatCurrency(p.variants[0].price, locale, currency)}</p>
                      <p className={"Desktop" === type ? "upsellplus-compare-at-price" : "upsellplus-compare-at-price upsellplus-compare-at-price-mobile"}>
                        { productPricePreview(p.compareAtPrice, p.variants[0].price, locale, currency) }
                      </p>
                    </div>
                      :
                      <p className="upsellplus-price">{p.variants && Array.isArray(p.variants) && p.variants.length > 0 ? formatCurrency(p.variants[0].price, locale, currency):""}</p>
                    :
                      (p.compareAtPrice && p.compareAtPrice.sym && "" !== p.compareAtPrice.value) || (p.compareAtPrice && !p.compareAtPrice.sym && "" !== p.compareAtPrice.value)?
                      <div className="upsellplus-price-container">
                        <p className={"Desktop" === type ? "upsellplus-price upsellplus-original-price" : "upsellplus-price upsellplus-original-price upsellplus-original-price-mobile"}>{formatCurrency(carouselSelectedVariant.price, locale, currency)}</p>
                        <p className={"Desktop" === type ? "upsellplus-compare-at-price" : "upsellplus-compare-at-price upsellplus-compare-at-price-mobile"}>
                          { productPricePreview(p.compareAtPrice, carouselSelectedVariant.price, locale, currency) }
                        </p>
                      </div>
                      :
                      <p className="upsellplus-price">{formatCurrency(carouselSelectedVariant.price, locale, currency)}</p>

                  :
                    false
                  
                )
                :
                false
              }
              {"Desktop" === type ?
              <button className="upsellplus-button upsellplus-button-desktop" style={{
                backgroundColor: ctaBackgroundColor,
                borderRadius: buttonBorderRadius,
                borderWidth: buttonBorder,
                color: ctaButtonTextColor,
                borderColor: ctaButtonBorderColor
              }} onClick={this.addToCart}>
                {this.state.loading ?
                  <Spinner accessibilityLabel="Loading Tags" size="small" />
                  :
                  <span className="upsellplus-button-content">
                    <span className="upsellplus-button-text">{cta || 'Add to cart'}</span>
                  </span>
                  }
              </button>
              :
              false
              }
          </div>
        { "Mobile" === type ?
        <button class="upsellplus-button upsellplus-button-mobile" style={{
            backgroundColor: ctaBackgroundColor,
            borderRadius: buttonBorderRadius,
            borderWidth: buttonBorder,
            color: ctaButtonTextColor,
            borderColor: ctaButtonBorderColor
          }} onClick={this.addToCart}>
            {this.state.loading ?
            <Spinner accessibilityLabel="Loading Tags" size="small" />
            :
            <span className="upsellplus-button-content">
              <span className="upsellplus-button-text">{cta || 'Add to cart'}</span>
            </span>
            }
          </button>
        :
        false
        }
      </div>
       })
      }
      </Flickity>
      </div>
      :
      Array.isArray(showProducts) ?
      (showProducts.length === 0 ?
        <div className="upsellplus-container" style={{
          minWidth:350,
          backgroundColor:offerBackgroundColor,
          borderRadius: widgetBorderRadius,
          borderWidth: widgetBorder,
          borderColor: widgetBorderColor
          }}>
            <div className="upsellplus-image-container">
                <img className="upsellplus-image" src='https://via.placeholder.com/200' alt="" />
            </div>
            <div className="upsellplus-title-container">
                <p className="upsellplus-title" style={{
                  color:ctaTextColor
                  }}>
                    {'Product title'}
                  </p>
                {"center" === generalLayout ?
                <p className="upsellplus-price upsellplus-price-left">{formatCurrency("0.00", locale, currency)}</p>
                :
                false
                }
                <p className="upsellplus-subtitle" style={{color:ctaTextColor}}>{'Product Subtitle'}</p>
                <p className="upsellplus-message" style={{color:ctaTextColor}}>{'Marketing message'}</p>
                
            </div>
            <div className="upsellplus-action-container">
                {"center" !== generalLayout ?
                <p className="upsellplus-price">{formatCurrency("0.00", locale, currency)}</p>
                :
                false
                }
                {"Desktop" === type ?
                <button className="upsellplus-button upsellplus-button-desktop" style={{
                  backgroundColor: ctaBackgroundColor,
                  borderRadius: buttonBorderRadius,
                  borderWidth: buttonBorder,
                  color: ctaButtonTextColor,
                  borderColor: ctaButtonBorderColor
                }} onClick={this.addToCart}>
                   {this.state.loading ?
                    <Spinner accessibilityLabel="Loading Tags" size="small" />
                    :
                    <span className="upsellplus-button-content">
                      <span className="upsellplus-button-text">{'Add to cart'}</span>
                    </span>
                    }
                </button>
                :
                false
                }
            </div>
           { "Mobile" === type ?
           <button class="upsellplus-button upsellplus-button-mobile" style={{
              backgroundColor: ctaBackgroundColor,
              borderRadius: buttonBorderRadius,
              borderWidth: buttonBorder,
              color: ctaButtonTextColor,
              borderColor: ctaButtonBorderColor
            }} onClick={this.addToCart}>
              {this.state.loading ?
              <Spinner accessibilityLabel="Loading Tags" size="small" />
              :
              <span className="upsellplus-button-content">
                <span className="upsellplus-button-text">{'Add to cart'}</span>
              </span>
              }
            </button>
           :
           false
           }
        </div>
        :
      showProducts.map((p,index) => {
        return <div className="upsellplus-container" key={index} style={{
          minWidth:350,
          backgroundColor:offerBackgroundColor,
          borderRadius: widgetBorderRadius,
          borderWidth: widgetBorder,
          borderColor: widgetBorderColor
          }}>
            <div className="upsellplus-image-container">
                <img className="upsellplus-image" 
                src={(shopBrain ?
                    productIcon
                  : 
                  ((!this.state.selection || (carouselSelectedVariant && this.state.selection.variants && p.variants && this.state.selection.variants[0].id !== p.variants[0].id)) ?
                  p.variants && Array.isArray(p.variants) && p.variants.length > 0 &&
                   (p.variants[0].image ? 
                   insertBeforeLastOccurrence(p.variants[0].image, ".", "_100x100") 
                   :
                   (p.product ?
                   insertBeforeLastOccurrence(p.product.image, ".", "_100x100") 
                    :
                    'https://via.placeholder.com/200'))
                  :  
                  carouselSelectedVariant && carouselSelectedVariant.image && insertBeforeLastOccurrence(carouselSelectedVariant.image, ".", "_100x100"))
                  )
                  || 'https://via.placeholder.com/200'}
                alt=""
                style={{padding: checkoutOfferType !== 'manual' ? '7px' : 'unset'}}
                />
            </div>
            <div className="upsellplus-title-container">
                <p className="upsellplus-title" style={{
                  color:ctaTextColor
                  }}>
                    {p.title || (p.product && p.product.title) || 'Product title'}
                  </p>
                  {"center" === generalLayout ? 
                 (
                  p.variants && Array.isArray(p.variants) && p.variants.length > 0 ?
                  (!carouselSelectedVariant || (carouselSelectedVariant && this.state.selection && this.state.selection.variants && this.state.selection.variants[0].id !== p.variants[0].id)) ?
                    (p.compareAtPrice && p.compareAtPrice.sym && "" !== p.compareAtPrice.value) || (p.compareAtPrice && !p.compareAtPrice.sym && "" !== p.compareAtPrice.value)?
                      <div className="upsellplus-price-container upsellplus-price-container-left">
                        <p className={"Desktop" === type ? "upsellplus-price upsellplus-original-price" : "upsellplus-price upsellplus-original-price upsellplus-original-price-mobile"}>{formatCurrency(p.variants[0].price, locale, currency)}</p>
                        <p className={"Desktop" === type ? "upsellplus-compare-at-price" : "upsellplus-compare-at-price upsellplus-compare-at-price-mobile"}>
                          { productPricePreview(p.compareAtPrice, p.variants[0].price, locale, currency) }
                        </p>
                      </div>
                      :
                      <p className="upsellplus-price upsellplus-price-left">{formatCurrency(p.variants[0].price, locale, currency)}</p>  
                    :
                    (p.compareAtPrice && p.compareAtPrice.sym && "" !== p.compareAtPrice.value) || (p.compareAtPrice && !p.compareAtPrice.sym && "" !== p.compareAtPrice.value)?
                      <div className="upsellplus-price-container upsellplus-price-container-left">
                        <p className={"Desktop" === type ? "upsellplus-price upsellplus-original-price" : "upsellplus-price upsellplus-original-price upsellplus-original-price-mobile"}>{formatCurrency(carouselSelectedVariant.price, locale, currency)}</p>
                        <p className={"Desktop" === type ? "upsellplus-compare-at-price" : "upsellplus-compare-at-price upsellplus-compare-at-price-mobile"}>
                          { productPricePreview(p.compareAtPrice, carouselSelectedVariant.price, locale, currency) }
                        </p>
                      </div>
                      :
                      <p className="upsellplus-price upsellplus-price-left">{formatCurrency(carouselSelectedVariant.price, locale, currency)}</p> 
                  :
                    false
                  
                )
                :
                false
              }
                <p className="upsellplus-subtitle" style={{color:ctaTextColor}}>{p.subtitle || (p.product ? '':'Product Subtitle')}</p>
                <p className="upsellplus-message" style={{color:ctaTextColor}}>{p.message || (p.product ? '':'Marketing message')}</p>
                {p.variants && Array.isArray(p.variants) && p.variants.length > 1 ?
                <select value={this.state.selection ? carouselSelectedVariant.title : p.variants[0].title} onChange={(event) => this.handleDropDownChange(p.variants, event)} className="upsellplus-selection">
                  {p.variants.map((variant, index) => 
                    <option value={variant.title} key={index}>{variant.title}</option>
                  )} 
                </select>
                :
                false
                }
            </div>
            <div className="upsellplus-action-container">
            {"center" !== generalLayout ?
                 (
                  p.variants && Array.isArray(p.variants) && p.variants.length > 0 ?
                  (!carouselSelectedVariant || (carouselSelectedVariant && this.state.selection && this.state.selection.variants && this.state.selection.variants[0].id !== p.variants[0].id)) ?
                    (p.compareAtPrice && p.compareAtPrice.sym && "" !== p.compareAtPrice.value) || (p.compareAtPrice && !p.compareAtPrice.sym && "" !== p.compareAtPrice.value)?
                      <div className="upsellplus-price-container">
                        <p className={"Desktop" === type ? "upsellplus-price upsellplus-original-price" : "upsellplus-price upsellplus-original-price upsellplus-original-price-mobile"}>{formatCurrency(p.variants[0].price, locale, currency)}</p>
                        <p className={"Desktop" === type ? "upsellplus-compare-at-price" : "upsellplus-compare-at-price upsellplus-compare-at-price-mobile"}>
                          { productPricePreview(p.compareAtPrice, p.variants[0].price, locale, currency) }
                        </p>
                      </div>
                      :
                      <p className="upsellplus-price">{formatCurrency(p.variants[0].price, locale, currency)}</p>  
                    :
                    (p.compareAtPrice && p.compareAtPrice.sym && "" !== p.compareAtPrice.value) || (p.compareAtPrice && !p.compareAtPrice.sym && "" !== p.compareAtPrice.value)?
                      <div className="upsellplus-price-container">
                        <p className={"Desktop" === type ? "upsellplus-price upsellplus-original-price" : "upsellplus-price upsellplus-original-price upsellplus-original-price-mobile"}>{formatCurrency(carouselSelectedVariant.price, locale, currency)}</p>
                        <p className={"Desktop" === type ? "upsellplus-compare-at-price" : "upsellplus-compare-at-price upsellplus-compare-at-price-mobile"}>
                          { productPricePreview(p.compareAtPrice, carouselSelectedVariant.price, locale, currency) }
                        </p>
                      </div>
                      :
                      <p className="upsellplus-price">{formatCurrency(carouselSelectedVariant.price, locale, currency)}</p> 
                  :
                    false
                  
                )
                :
                false
              }
              {"Desktop" === type ?
              <button className="upsellplus-button upsellplus-button-desktop" style={{
                backgroundColor: ctaBackgroundColor,
                borderRadius: buttonBorderRadius,
                borderWidth: buttonBorder,
                color: ctaButtonTextColor,
                borderColor: ctaButtonBorderColor
              }} onClick={this.addToCart}>
                {this.state.loading ?
                  <Spinner accessibilityLabel="Loading Tags" size="small" />
                  :
                  <span className="upsellplus-button-content">
                    <span className="upsellplus-button-text">{cta || 'Add to cart'}</span>
                  </span>
                  }
              </button>
              :
              false
              }
            </div>
            { "Mobile" === type ?
        <button class="upsellplus-button upsellplus-button-mobile" style={{
            backgroundColor: ctaBackgroundColor,
            borderRadius: buttonBorderRadius,
            borderWidth: buttonBorder,
            color: ctaButtonTextColor,
            borderColor: ctaButtonBorderColor
          }} onClick={this.addToCart}>
            {this.state.loading ?
            <Spinner accessibilityLabel="Loading Tags" size="small" />
            :
            <span className="upsellplus-button-content">
              <span className="upsellplus-button-text">{cta || 'Add to cart'}</span>
            </span>
            }
          </button>
        :
        false
        }
        </div>
      })
      )
      :
      <div className="upsellplus-container" style={{
        minWidth:350,
        backgroundColor:offerBackgroundColor,
        borderRadius: widgetBorderRadius,
        borderWidth: widgetBorder,
        borderColor: widgetBorderColor
        }}>
          <div className="upsellplus-image-container">
              <img className="upsellplus-image" src={(shopBrain ? 'https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/smart-rules.png?alt=media&token=dc855a7c-567e-40b2-941f-df99fa0986d7' : (variants && selectedVariant && selectedVariant.image ? insertBeforeLastOccurrence(selectedVariant.image, ".", "_100x100") : (image && insertBeforeLastOccurrence(image, ".", "_100x100")))) || 'https://via.placeholder.com/200'} alt="" />
          </div>
          <div className="upsellplus-title-container">
              <p className="upsellplus-title" style={{
                color:ctaTextColor
                }}>
                  {title || 'Offer title'}
                </p>
                {"center" === generalLayout ? 
                (discount && (
                  <div>
                    <span className="upsellplus-original-price">{formatCurrency(price,locale,currency)}</span>
                    <span className="upsellplus-discounted-price">{formatCurrency((+price * ((100 - discount) / 100)).toFixed(2), locale, currency)}</span>
                  </div>
                ) || (
                  variants?
                  (compareAtPrice.sym && "" !== compareAtPrice.value) || (!compareAtPrice.sym && "" !== compareAtPrice.value)?
                    <div className="upsellplus-price-container upsellplus-price-container-left">
                      <p className={"Desktop" === type ? "upsellplus-price upsellplus-original-price" : "upsellplus-price upsellplus-original-price upsellplus-original-price-mobile"}>{formatCurrency(selectedVariant.price, locale, currency)}</p>
                      <p className={"Desktop" === type ? "upsellplus-compare-at-price" : "upsellplus-compare-at-price upsellplus-compare-at-price-mobile"}>
                        { productPricePreview(compareAtPrice, selectedVariant.price, locale, currency) }
                      </p>
                    </div>
                    :
                    <p className="upsellplus-price upsellplus-price-left">{formatCurrency(selectedVariant.price, locale, currency)}</p>   
                  :
                    (compareAtPrice.sym && "" !== compareAtPrice.value) || (!compareAtPrice.sym && "" !== compareAtPrice.value)?
                    <div className="upsellplus-price-container upsellplus-price-container-left">
                      <p className={"Desktop" === type ? "upsellplus-price upsellplus-original-price" : "upsellplus-price upsellplus-original-price upsellplus-original-price-mobile"}>{formatCurrency(price, locale, currency)}</p>
                      <p className={"Desktop" === type ? "upsellplus-compare-at-price" : "upsellplus-compare-at-price upsellplus-compare-at-price-mobile"}>
                        { productPricePreview(compareAtPrice, selectedVariant.price, locale, currency) }
                      </p>
                    </div>
                    :
                    <p className="upsellplus-price upsellplus-price-left">{formatCurrency(price, locale, currency)}</p>  
                  
                )
              )
              :
              false
              }
              <p className="upsellplus-subtitle" style={{color:ctaTextColor}}>{subtitle || 'Offer subtitle'}</p>
              <p className="upsellplus-message" style={{color:ctaTextColor}}>{message}</p>
              {variants && variants.length > 1 ?
                <select value={this.state.title} onChange={this.handleChange} className="upsellplus-selection">
                  {variants.map((variant,index) => 
                    <option value={variant.title} key={index}>{variant.title}</option>
                  )} 
                </select>
                :
                false
              }
          </div>
          <div className="upsellplus-action-container">
              { "center" !== generalLayout ? 
                (discount && (
                  <div>
                    <span className="upsellplus-original-price">{formatCurrency(price,locale,currency)}</span>
                    <span className="upsellplus-discounted-price">{formatCurrency((+price * ((100 - discount) / 100)).toFixed(2), locale, currency)}</span>
                  </div>
                ) || (
                  variants?
                  (compareAtPrice.sym && "" !== compareAtPrice.value) || (!compareAtPrice.sym && "" !== compareAtPrice.value)?
                    <div className="upsellplus-price-container">
                      <p className={"Desktop" === type ? "upsellplus-price upsellplus-original-price" : "upsellplus-price upsellplus-original-price upsellplus-original-price-mobile"}>{formatCurrency(selectedVariant.price, locale, currency)}</p>
                      <p className={"Desktop" === type ? "upsellplus-compare-at-price" : "upsellplus-compare-at-price upsellplus-compare-at-price-mobile"}>
                        { productPricePreview(compareAtPrice, selectedVariant.price, locale, currency) }
                      </p>
                    </div>
                    :
                    <p className="upsellplus-price">{formatCurrency(selectedVariant.price, locale, currency)}</p>   
                  :
                    (compareAtPrice.sym && "" !== compareAtPrice.value) || (!compareAtPrice.sym && "" !== compareAtPrice.value)?
                    <div className="upsellplus-price-container">
                      <p className={"Desktop" === type ? "upsellplus-price upsellplus-original-price" : "upsellplus-price upsellplus-original-price upsellplus-original-price-mobile"}>{formatCurrency(price, locale, currency)}</p>
                      <p className={"Desktop" === type ? "upsellplus-compare-at-price" : "upsellplus-compare-at-price upsellplus-compare-at-price-mobile"}>
                        { productPricePreview(compareAtPrice, selectedVariant.price, locale, currency) }
                      </p>
                    </div>
                    :
                    <p className="upsellplus-price">{formatCurrency(price, locale, currency)}</p>  
                  
                ))
                :
                false
              }
              {"Desktop" === type ?
              <button className="upsellplus-button upsellplus-button-desktop" style={{
                backgroundColor: ctaBackgroundColor,
                borderRadius: buttonBorderRadius,
                borderWidth: buttonBorder,
                color: ctaButtonTextColor,
                borderColor: ctaButtonBorderColor
              }} onClick={this.addToCart}>
                 {this.state.loading ?
                  <Spinner accessibilityLabel="Loading Tags" size="small" />
                  :
                  <span className="upsellplus-button-content">
                    <span className="upsellplus-button-text">{cta || 'Add to cart'}</span>
                  </span>
                  }
              </button>
              :
              false
              }
          </div>
         { "Mobile" === type ?
         <button class="upsellplus-button upsellplus-button-mobile" style={{
            backgroundColor: ctaBackgroundColor,
            borderRadius: buttonBorderRadius,
            borderWidth: buttonBorder,
            color: ctaButtonTextColor,
            borderColor: ctaButtonBorderColor
          }} onClick={this.addToCart}>
            {this.state.loading ?
            <Spinner accessibilityLabel="Loading Tags" size="small" />
            :
            <span className="upsellplus-button-content">
              <span className="upsellplus-button-text">{cta || 'Add to cart'}</span>
            </span>
            }
          </button>
         :
         false
         }
      </div>
      }
   </div>
   </UpsellPlusWrapper>
  )
          }
}

export default UpsellPreview;